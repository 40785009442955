import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import Image from './image';

const IndexPage = () => (
  <Layout>
    <p>Capturing the memories, as you and your family learn, at home</p>
  </Layout>
);

export default IndexPage;
